import { Injectable } from '@angular/core';
import CONSTANTS from '../constants';
import { HttpClient } from './http-client.service';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private httpClient: HttpClient) { }

  public async getValue(key: string): Promise<any> {
    const response = await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/setting/?key=${key}`);
    return response.data;
  }
}
