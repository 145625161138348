import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { ComponentsModule } from '../components/components.module';

import { IndexPageComponent } from './index-page/index-page.component';
import { ServicesPageComponent } from './services-page/services-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { NewsPageComponent } from './news-page/news-page.component';
import { QualificationPageComponent } from './qualification-page/qualification-page.component';
import { ProductPageComponent } from './product-page/product-page.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    IndexPageComponent,
    ServicesPageComponent,
    ContactUsPageComponent,
    NewsPageComponent,
    QualificationPageComponent,
    ProductPageComponent],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US }
  ],
})
export class PagesModule { }
