import { Injectable } from '@angular/core';
import { HttpClient } from './http-client.service';
import CONSTANTS from '../constants';
import { SearchResponse, Product } from '../models/model';

@Injectable({
    providedIn: 'root'
})
export class ProductService {

    constructor(private httpClient: HttpClient) { }

    public async getCategories() {
        return await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/product/category-tree`);
    }

    public async searchByName(name: string): Promise<SearchResponse> {
        const response = await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/product/list?name=${name}`);
        if (response.status === 200) {
            return response.data as SearchResponse;
        } else {
            throw new Error('search failure');
        }
    }

    public async searchByCategoryId(categoryId: number | string | string[]): Promise<SearchResponse> {
        const response = await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/product/list?categoryId=${categoryId}`);
        if (response.status === 200) {
            return response.data as SearchResponse;
        } else {
            throw new Error('search failure');
        }
    }

    public async searchById(id: string | number): Promise<Product> {
        const response = await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/product?id=${id}`);
        if (response.status === 200) {
            return response.data as Product;
        } else {
            throw new Error('search failure');
        }
    }

    public async getProductPage(pageId: number) {
        const response =  await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/page/${pageId}`);
        return response.data;
    }
}
