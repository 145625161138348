import { Injectable } from '@angular/core';
import { HttpClient } from './http-client.service';
import { NewsSearchRequestVO, SearchResponse, News } from '../models/model';
import CONSTANTS from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient) { }

  public async search(params: NewsSearchRequestVO): Promise<SearchResponse> {

    params.page_index = params.page_index !== undefined ? params.page_index : 0;
    params.page_size = params.page_size !== undefined ? params.page_size : 10;

    const response = await this.httpClient.get(
      `${CONSTANTS.SERVER_URL}/api/news?page_index=${params.page_index}&page_size=${params.page_size}&key=${params.title ? params.title : ''}&language=${params.language ? params.language : ''}`
    );

    if (response.status === 200) {
      return response.data as SearchResponse;
    } else {
      throw new Error('search news failure');
    }
  }

  public async searchById(id: number): Promise<News> {
    const response = await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/news/${id}`);
    if (response.status === 200) {
      return response.data as News;
    } else {
      throw new Error('search news failure');
    }
  }

  public async getNewsPage(pageId: number) {
    const response =  await this.httpClient.get(`${CONSTANTS.SERVER_URL}/api/page/${pageId}`);
    return response.data;
 }
}
