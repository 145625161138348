import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-index-page',
  templateUrl: './index-page.component.html',
  styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements OnInit {

  public isSpinning = false;
  public searchKey: string;
  public data: any = {
    contactInfoKey: 'contact_info',
    homePageKey: 'home_page',
    aboutContent: '',
    contact: {
      tel: '',
      fax: '',
      email: '',
      addr: ''
    }
  };

  constructor(
    private readonly router: Router,
    private readonly appService: AppService
  ) { }

  ngOnInit() {
    this.isSpinning = true;
    this.appService.subscribe(
      global => this.update(global),
      error => { console.error(error); },
      () => {
        this.isSpinning = false;
      });
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.about && global.contact) {
      this.data.aboutContent = global.about;
      this.data.contact = global.contact;
      this.isSpinning = false;
    }
  }

  onSearch() {
    if (this.searchKey && this.searchKey.trim().length > 0) {
      this.router.navigateByUrl(`/products?search=${this.searchKey.trim()}`);
    }
  }

  onSearchByCategory(categoryId: number) {
    if (categoryId) {
      this.router.navigateByUrl(`/products?category=${categoryId}`);
    }
  }

}
