import { Component, OnInit } from '@angular/core';
import { ServiceItem } from 'src/app/models/service-item';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss']
})
export class ServicesPageComponent implements OnInit {

  public ourServicesInfo = '';
  public items: ServiceItem[] = [
    {
      label: 'Manufacturing',
      description: '',
      icon: 'gear',
      animateType: 'fadeInLeft'
    },
    {
      label: 'Import and Export',
      description: '',
      icon: 'earth',
      animateType: 'zoomIn'
    },
    {
      label: 'Product customization',
      description: '',
      icon: 'microscope',
      animateType: 'fadeInRight'
    },
  ];

  constructor(
    private readonly appService: AppService
  ) { }

  ngOnInit() {
    this.appService.subscribe(global => this.update(global));
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.ourServices) {
      this.ourServicesInfo = global.ourServices.info;
      this.items[0].description = global.ourServices.subInfo.materialProcessing;
      this.items[1].description = global.ourServices.subInfo.importAndExport;
      this.items[2].description = global.ourServices.subInfo.scientificResearch;
    }
  }

}
