import { Component, OnInit } from '@angular/core';
import { QualificationItem } from 'src/app/models/qualification-item';
import { AppService } from 'src/app/services/app.service';
import CONSTANTS from 'src/app/constants';
import { CrumbsService } from 'src/app/services/crumbs.service';

@Component({
  selector: 'app-qualification-page',
  templateUrl: './qualification-page.component.html',
  styleUrls: ['./qualification-page.component.scss']
})
export class QualificationPageComponent implements OnInit {

  public qualificationItems = [
  ];
  public isVisible = false;
  public selected?: QualificationItem = undefined;

  constructor(
    private readonly appService: AppService,
    private readonly crumbsService : CrumbsService
  ) { }

  ngOnInit() {
    this.crumbsService.setPath("QUALIFCATION");
    this.appService.subscribe(global => this.update(global));
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.qualifications) {
      this.qualificationItems = global.qualifications.map(item => {
        return {
          name: item.name,
          imageUrl: `${CONSTANTS.SERVER_URL}/images/${item.imageUrl}`
        };
      });
    }
  }

  onSelect(qualificationItem: QualificationItem) {
    this.selected = qualificationItem;
    this.isVisible = true;
  }

  onClose() {
    this.isVisible = false;
    this.selected = undefined;
  }

}
