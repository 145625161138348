import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QualificationItem } from 'src/app/models/qualification-item';

@Component({
  selector: 'app-qualification-item',
  templateUrl: './qualification-item.component.html',
  styleUrls: ['./qualification-item.component.scss']
})
export class QualificationItemComponent implements OnInit {

  @Input()
  public item: QualificationItem;

  @Output()
  private onSelect = new EventEmitter<QualificationItem>();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.onSelect) {
      this.onSelect.emit(this.item);
    }
  }

}
