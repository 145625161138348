import { Component, OnInit, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import CONSTANTS from 'src/app/constants';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  public backgroundImage = '';

  @Input()
  public shortHeader: boolean;

  constructor(
    private readonly appService: AppService
  ) { }

  ngOnInit() {
    this.appService.subscribe(global => this.update(global));
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.banners && global.banners.length > 0) {
      const index = Math.floor(Math.random() * global.banners.length);
      this.backgroundImage = `url("${CONSTANTS.SERVER_URL}/images/banners/${global.banners[index].imageUrl}")`;
    }
  }

}
