import { Injectable, EventEmitter } from '@angular/core';
import { SettingService } from './setting.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly SITE_KEY = 'site-config';
  private readonly CONTACT_INFO_KEY = 'contact_info';
  private readonly HOME_PAGE_KEY = 'home_page';
  private readonly MAP_KEY = 'map';
  private readonly FOOTER_KEY = 'footer';
  private readonly OUR_SERVICES = 'our-services';
  private readonly QUALIFICATIONS = 'qualifications';
  private readonly eventEmitter = new EventEmitter<any>();

  public global: any = undefined;

  constructor(
    private readonly settingService: SettingService
  ) {

  }

  subscribe(generatorOrNext?: any, error?: any, complete?: any): Subscription {
    return this.eventEmitter.subscribe(generatorOrNext, error, complete);
  }

  async loadGlobalData() {

    if (this.global) {
      return this.global;
    }

    this.global = {};

    let result: any = await this.settingService.getValue(this.HOME_PAGE_KEY);
    this.global.about = result.about;

    result = await this.settingService.getValue(this.CONTACT_INFO_KEY);
    this.global.contact = {
      tel: result.tel,
      email: result.email,
      addr: result.addr,
      fax: result.fax,
    };

    const siteConfig = await this.settingService.getValue(this.SITE_KEY);
    this.global.banners = siteConfig.banners;
    this.global.map = await this.settingService.getValue(this.MAP_KEY);
    this.global.footer = await this.settingService.getValue(this.FOOTER_KEY);
    this.global.ourServices = await this.settingService.getValue(this.OUR_SERVICES);
    this.global.qualifications = await this.settingService.getValue(this.QUALIFICATIONS);

    this.eventEmitter.emit(this.global);

    return this.global;
  }
}
