import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { BannerComponent } from './banner/banner.component';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { RouterModule } from '@angular/router';
import { ServiceItemComponent } from './service-item/service-item.component';
import { NewsItemComponent } from './news-item/news-item.component';
import { QualificationItemComponent } from './qualification-item/qualification-item.component';
import { MessageBoardComponent } from './message-board/message-board.component';
import { CrumbsComponent } from './crumbs/crumbs.component';

@NgModule({
  declarations: [FooterComponent, BannerComponent, ServiceItemComponent, NewsItemComponent, QualificationItemComponent, MessageBoardComponent, CrumbsComponent],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    FooterComponent,
    BannerComponent,
    ServiceItemComponent,
    NewsItemComponent,
    QualificationItemComponent,
    MessageBoardComponent,
    CrumbsComponent
  ]
})
export class ComponentsModule { }
