interface ConstantsType {
    readonly SERVER_URL: string;
    readonly AUTHORIZATION_NAME: string;
    readonly LANGUAGE_LOCALE: string;
    readonly LANGUAGE_LOCALE_EXPIRES: number;
}

const serverUrl = 'https://www.techwuhan.com';

const CONSTANTS: ConstantsType = {
    SERVER_URL: serverUrl,
    AUTHORIZATION_NAME: 'authorization',
    LANGUAGE_LOCALE: 'locale',
    LANGUAGE_LOCALE_EXPIRES: 30
};

export default CONSTANTS;
