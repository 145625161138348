import { Injectable } from '@angular/core';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import CONSTANTS from '../constants';
import * as Cookies from 'js-cookie';

const requestConfig: AxiosRequestConfig = {
  headers: {
      'Content-Type': 'application/json',
      'accept': '*/*'
  }
};

@Injectable({
  providedIn: 'root'
})
export class HttpClient {

  constructor() { }

  public async get<T>(url: string, config: AxiosRequestConfig = {}) {
    return await axios.get<T>(url, this.setRequestConfigAndAuthorization(config));
}

public async post<T>(url: string, params: any = {}, config: AxiosRequestConfig = {}) {
    return await axios.post<T>(url, params, this.setRequestConfigAndAuthorization(config));
}

public async patch<T>(url: string, params: any = {}, config: AxiosRequestConfig = {}) {
    return await axios.patch<T>(url, params, this.setRequestConfigAndAuthorization(config));
}

public async put<T>(url: string, params: any = {}, config: AxiosRequestConfig = {}) {
    return await axios.put<T>(url, params, this.setRequestConfigAndAuthorization(config));
}


public async delete(url: string, params: any = {}, config: AxiosRequestConfig = {}) {
    return await axios.delete(url, this.setRequestConfigAndAuthorization(config));
}

private setRequestConfigAndAuthorization(config: AxiosRequestConfig): AxiosRequestConfig {
    if (config) {
        Object.assign(config, requestConfig);
    }
    // 检查cookie, 如果cookie有token，就放入header
    const token = Cookies.get(CONSTANTS.AUTHORIZATION_NAME);
    if (token) {
        config.headers = config.headers ? config.headers : {};
        config.headers[CONSTANTS.AUTHORIZATION_NAME] = token;
    }

    return config;
}
}
