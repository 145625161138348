import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class CrumbsService {

  private readonly eventEmitter = new EventEmitter<any>();
  private path: string;

  constructor() { }

  subscribe(generatorOrNext?: any, error?: any, complete?: any): Subscription {
    return this.eventEmitter.subscribe(generatorOrNext, error, complete);
  }

  public setPath(path: string) {
    this.path = path;
    this.eventEmitter.emit(path);
  }

  public getPath(): string {
    return this.path;
  }

}
