import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public footerInfo: any = {};

  constructor(
    private readonly appService: AppService
  ) { }

  ngOnInit() {
    this.appService.subscribe(global => this.update(global));
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.footer) {
      this.footerInfo = global.footer.info;
    }
  }

}
