import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss']
})
export class ContactUsPageComponent implements OnInit {

  public data: any = {
    map: {
      url: undefined
    },
    contact: {
      tel: '',
      email: '',
      addr: ''
    }
  };

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly appService: AppService
  ) { }

  ngOnInit() {
    this.appService.subscribe(global => this.update(global));
    this.appService.loadGlobalData().then(global => this.update(global));
  }

  update(global: any) {
    if (global && global.contact && global.map) {
      this.data.contact = global.contact;
      this.data.map.url = this.sanitizer.bypassSecurityTrustResourceUrl(global.map.url);
    }
  }

}
