import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { NewsPageComponent } from './pages/news-page/news-page.component';
import { QualificationPageComponent } from './pages/qualification-page/qualification-page.component';


const routes: Routes = [
  { path: '', component: IndexPageComponent },
  { path: 'services', component: ServicesPageComponent},
  { path: 'contactus', component: ContactUsPageComponent},
  { path: 'products', component: ProductPageComponent},
  { path: 'news', component:NewsPageComponent},
  { path: 'qualification', component: QualificationPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
