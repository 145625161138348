import { Component, OnInit } from '@angular/core';
import { CrumbsService } from 'src/app/services/crumbs.service';

@Component({
  selector: 'app-crumbs',
  templateUrl: './crumbs.component.html',
  styleUrls: ['./crumbs.component.scss']
})
export class CrumbsComponent implements OnInit {

  public path: string = "";

  constructor(
    private readonly crumbsService: CrumbsService
  ) { }

  ngOnInit() {
    this.path = this.crumbsService.getPath();
    this.crumbsService.subscribe(path => {
      this.path = path;
    })
  }

}
