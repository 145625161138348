import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewsItem } from 'src/app/models/news-item';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {

  @Input()
  public newsItem: NewsItem;

  @Output()
  private onSelect = new EventEmitter<NewsItem>();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    if (this.onSelect) {
      this.onSelect.emit(this.newsItem);
    }
  }

}
