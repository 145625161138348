import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NzTreeNodeOptions, NzTreeNode } from 'ng-zorro-antd/core/tree/nz-tree-base-node';
import { SettingService } from 'src/app/services/setting.service';
import { ProductService } from 'src/app/services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CrumbsService } from 'src/app/services/crumbs.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
  encapsulation:ViewEncapsulation.None,
})
export class ProductPageComponent implements OnInit {

  public searchKey: string;
  public nodes: NzTreeNodeOptions[] = [];
  public products: any[] = [];

  constructor(
    private settingService: SettingService,
    private productService: ProductService,
    private router: ActivatedRoute,
    private readonly crumbsService : CrumbsService
  ) { }

  ngOnInit() {

    this.crumbsService.setPath("PRODUCT");

    this.settingService
      .getValue('categories-tree')
      .then((res: any[]) => {
        this.nodes = res.map(item => this.transform(item));
      })
      .then(()=>{
        this.router.queryParams.subscribe(quryParams => {
          if (quryParams.search && quryParams.search.length > 0) {
            this.searchKey = quryParams.search;
            this.onSearch();
          } else if (quryParams.category && quryParams.category.length > 0){
            this.getProductsByCategoryId(parseInt(quryParams.category));
          }
        });
      });
  }

  public nzClick(event: any) {
    const node: NzTreeNode = event.node;
    this.getProductsByNode(node);
  }

  public onSearch() {
    if (this.searchKey && this.searchKey.length > 0) {
      this
        .productService
        .searchByName(this.searchKey)
        .then((result: any) => {
          this.products = result.total > 0 ? result.rows : [];
        });
    }
  }

  public onSelectProduct(product: any) {
    if (!product.pageContent) {
      this.productService.getProductPage(product.en_page_id).then((result: any) => {
        product.pageContent = result.content;
      });
    }
  }

  private transform(item: any): NzTreeNodeOptions {
    const hasChildren: boolean = item.subs && item.subs.length > 0;
    return {
      title: item.en_name.toUpperCase(),
      key: item.id,
      children: hasChildren ? item.subs.map((subitem: any) => this.transform(subitem)) : null,
      isLeaf: hasChildren ? false : true
    };
  }

  private getIdsFromCategoryNode(node: NzTreeNode): any[] {
    const ids: any[] = [node.key];
    if (node.getChildren()) {
      node.getChildren().forEach(childrenNode => {
        ids.push(this.getIdsFromCategoryNode(childrenNode));
      });
    }

    return ids;
  }

  private getIdsFromCategoryNodeOptions(node: NzTreeNodeOptions): any[] {
    const ids: any[] = [node.key];
    if (node.children) {
      node.children.forEach(childNodeOptions => {
        ids.push(this.getIdsFromCategoryNodeOptions(childNodeOptions));
      });
    }

    return ids;
  }

  private getProductsByNode(node: NzTreeNode) {
    const ids = this.getIdsFromCategoryNode(node);
    this.productService.searchByCategoryId(ids.join(',')).then(result => {
      this.products = result.total > 0 ? result.rows : [];
    });
  }

  private getProductsByCategoryId(categoryId: number) {
    const nodeOptions = this.getNodeOptionsWithCategoryId(categoryId);
    const ids = this.getIdsFromCategoryNodeOptions(nodeOptions);
    this.productService.searchByCategoryId(ids.join(',')).then(result => {
      this.products = result.total > 0 ? result.rows : [];
    });
  }

  private getNodeOptionsWithCategoryId(id: number | string): NzTreeNodeOptions {
    return this.nodes.find(node => {
      return node.key === id;
    });
  }

  private getNodeOptionsWithNode(targetNode: NzTreeNode): NzTreeNodeOptions {
    return this.nodes.find(node => {
      return node.key === targetNode.key;
    });
  }
}
