import { Component, OnInit } from '@angular/core';
import { HttpClient } from 'src/app/services/http-client.service';
import CONSTANTS from 'src/app/constants';
import { AxiosResponse } from 'axios';

@Component({
  selector: 'app-message-board',
  templateUrl: './message-board.component.html',
  styleUrls: ['./message-board.component.scss']
})
export class MessageBoardComponent implements OnInit {

  public recaptchaImageData: string;
  public recaptcha: string;

  constructor(private readonly httpClient: HttpClient) { }

  ngOnInit() {

    this.updateRecaptcha();
  }

  onSubmit(form: any): void {
    if (form.form.status === "INVALID") {
      alert('Send message success, Thank you.');
      return;
    }
    this.httpClient
      .post(`${CONSTANTS.SERVER_URL}/api/msg`, form.value)
      .then((response: AxiosResponse<string>) => {
        if (response.status === 200) {
          alert('Send message success, Thank you.');
        }
      })
      .catch(err => {
        alert(`Send message failed, ${err.response.data}`);
      })
      .finally(()=>{
        this.updateRecaptcha();
      });
     
  }

  updateRecaptcha(): Promise<any> {
    return this.httpClient
      .get(`${CONSTANTS.SERVER_URL}/api/recaptcha`)
      .then((response: AxiosResponse<string>) => {
        this.recaptcha = response.headers.recaptcha;
        this.recaptchaImageData = `data:image/png;base64,${response.data}`;
      });
  }
}
