import { Component, OnInit, Input } from '@angular/core';
import { ServiceItem } from 'src/app/models/service-item';

@Component({
  selector: 'service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss']
})
export class ServiceItemComponent implements OnInit {

  @Input()
  public item:ServiceItem;

  constructor() { }

  ngOnInit() {
    
  }

}
