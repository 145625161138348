import { Component, OnInit } from '@angular/core';
import { NewsItem } from 'src/app/models/news-item';
import { NewsService } from 'src/app/services/news.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CrumbsService } from 'src/app/services/crumbs.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss']
})
export class NewsPageComponent implements OnInit {

  public news: NewsItem[];
  public isVisibleNewsPage = false;
  public newsPageTitle = '';
  public nenwsPageContent = '';
  public pagination = {
    index: 0,
    total: 0,
    pageSize: 0
  }

  constructor(
    private readonly newsService: NewsService,
    private readonly utils: UtilsService,
    private readonly crumbsService : CrumbsService
  ) {
  }

  ngOnInit() {
    this.crumbsService.setPath("NEWS");
    this.newsService.search({ language: '2' }).then(result => {
      if (result.rows && result.rows.length > 0) {
        this.pagination.index = result.page_index + 1;
        this.pagination.pageSize = result.page_size;
        this.pagination.total = result.total;
        this.news = result.rows.map(news => {
          return {
            image: '',
            title: news.title,
            description: '',
            publishTime: this.utils.formatDatetime(news.create_time),
            commentsCount: 0,
            id: news.id,
            pageId: news.page_id
          };
        });
      }
    });
  }

  onSelect(newsItem: NewsItem) {
    this.newsService.getNewsPage(newsItem.pageId).then((result: any) => {
      this.newsPageTitle = result.title;
      this.nenwsPageContent = result.content;
      this.isVisibleNewsPage = true;
    });
  }

  onCloseNewsPage() {
    this.isVisibleNewsPage = false;
  }

  onPageIndexChange(index: number) {
    this.newsService.search({ page_index: index - 1, language: '2' }).then(result => {
      if (result.rows && result.rows.length > 0) {
        this.pagination.index = result.page_index + 1;
        this.pagination.pageSize = result.page_size;
        this.pagination.total = result.total;
        this.news = result.rows.map(news => {
          return {
            image: '',
            title: news.title,
            description: '',
            publishTime: this.utils.formatDatetime(news.create_time),
            commentsCount: 0,
            id: news.id,
            pageId: news.page_id
          };
        });
      }
    });
  }

}
